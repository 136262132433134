<template>
  <div>
    <b-button
      v-if="!hideButton"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="initFmModal"
    >
      {{ $t('general.upload') }}
    </b-button>

    <b-modal
      id="modal-fm"
      ref="modal-fm"
      centered
      size="xl"
      :title="$t('general.file_manager')"
      hide-footer
    >
      <file-manager :settings="settings" />
      <b-row>
        <b-col
          cols="2"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-secondary"
            block
            @click="hideFmModal"
          >
            {{ $t('general.close') }}
          </b-button>
        </b-col>
        <b-col
          cols="8"
          md="8"
        />
        <b-col
          cols="2"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="primary"
            block
            @click="selectFmFiles"
          >
            {{ $t('general.select') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCol,
    BModal,
    BButton,
    BRow,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    hideButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      settings: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
        baseUrl: `${process.env.VUE_APP_API_URL}/file-manager`,
        windowsConfig: 2,
        showNaw: true,
        lang: this.$i18n.locale,
      },
      fm: {
        field: null, multiple: false,
      },
    }
  },
  methods: {
    initFmModal() {
      this.fm.multiple = this.multiple
      this.fm.field = this.field
      this.$refs['modal-fm'].show()

      if (this.path) {
        setTimeout(() => {
          this.setDirectory(this.path)
        }, 2000)
      }
    },
    hideFmModal() {
      this.$refs['modal-fm'].hide()
    },
    setDirectory(path) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/file-manager/content`, {
        params: {
          disk: 'file-manager',
          path,
        },
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          accept: '*/*',
        },
      }).then(response => {
        if (response.data.result.status === 'success') {
          this.$store.commit('fm/left/setDirectoryContent', response.data)
          this.$store.commit('fm/right/setDirectoryContent', response.data)
          this.$store.commit('fm/left/setSelectedDirectory', path)
          this.$store.commit('fm/right/setSelectedDirectory', path)
        }
      })
    },
    async selectFmFiles() {
      const files = _.filter(this.$store.getters['fm/selectedItems'], item => item.type === 'file')

      if (!Object.keys(files).length) {
        this.$emit('fm-selected', null)
        this.hideFmModal()
        return
      }

      this.$http.get('/api/media', {
        params: {
          path: _.map(files, 'path'),
        },
      })
        .then(response => {
          const { data } = response.data

          const { type } = this

          _.map(data, item => {
            item.meta_type = 'file'
            item.type = type
            return item
          })

          this.$emit('fm-selected', this.fm.multiple ? data : data[0])

          this.hideFmModal()
        })
        .catch(() => {
          this.$emit('fm-selected', null)
          this.hideFmModal()
        })
    },
  },
}
</script>

<template>
  <div>
    <quill-editor
      :id="id"
      ref="editorQuill"
      v-model="localContent"
      :options="editorOptions"
      :state="state"
    />
    <InitFileManager
      ref="editorFm"
      field="fmImage"
      type="fmImage"
      :multiple="false"
      :hide-button="true"
      @fm-selected="selectMedia"
    />
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
// eslint-disable-next-line
import 'quill-table/src/css/quill.table.css'

import InitFileManager from '@/components/InitFileManager.vue'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import ImageResize from 'quill-image-resize-vue'
import quillTable from 'quill-table'

class ImageUploader {
  constructor(quill, options) {
    this.quill = quill
    this.options = options

    const toolbar = quill.getModule('toolbar')
    toolbar.addHandler('image', this.selectLocalImage.bind(this))
  }

  selectLocalImage() {
    this.options.openFileManager()
  }

  insertToEditor(url) {
    const range = this.quill.getSelection()
    this.quill.insertEmbed(range.index, 'image', url)
  }
}

Quill.register('modules/htmlEditButton', htmlEditButton)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageUploader', ImageUploader)
Quill.register(quillTable.TableCell)
Quill.register(quillTable.TableRow)
Quill.register(quillTable.Table)
Quill.register(quillTable.Contain)
Quill.register('modules/table', quillTable.TableModule)

export default {
  components: {
    InitFileManager,
    quillEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
    },
  },
  data() {
    return {
      content: this.value,
      editorOptions: {
        modules: {
          table: {},
          htmlEditButton: {},
          imageResize: {
            modules: [
              'DisplaySize',
              'Resize',
            ],
          },
          imageUploader: {
            openFileManager: this.openFileManager,
            selectMedia: this.selectMedia,
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],

            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],

            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],

            ['clean', 'link', 'image', 'video'],

            [{ table: 'newtable_2_2' }, { table: 'append-row' }, { table: 'append-col' }],
          ],
        },
      },
    }
  },
  computed: {
    localContent: {
      get() {
        return this.content
      },
      set(value) {
        this.content = value
        this.$emit('input', this.content)
      },
    },
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
  },
  methods: {
    openFileManager() {
      this.$refs.editorFm.initFmModal()
    },
    selectMedia(data) {
      if (!data?.url) return
      this.insertToEditor(data.url)
    },
    insertToEditor(url) {
      const range = this.$refs.editorQuill.quill.getSelection()
      this.$refs.editorQuill.quill.insertEmbed(range.index, 'image', url)
    },
  },
}
</script>
